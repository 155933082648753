import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useState,
  useEffect,
} from 'react';
import { io } from 'socket.io-client';

import { IPocAiContext, PocAiProviderProps } from './interfaces';
import { IPocMessage, PocMessageType, EAudioPlaybackType } from '../interfaces';
import { visualizeAudio } from './avatarVisualizer';
import {
  POC_SCREENS,
  audioZoneScript,
  teleportPlaces,
  assistantSize,
  greetingsText,
  greetingsTextSecondary,
  actionsPromptOptionsExpression,
  actionsPromptOptionsTeleport,
  keys,
  celebratoryMomentAudioZone,
  defaultBedrockReply,
} from '../constants';
import { useMytaverse } from '../../../../../providers/MytaverseProvider';
import { useMytaverseEvent } from '../../../../dashboard/providers/MytaverseEventProvider';

import {
  getLocalStorageValue,
  setLocalStorageValue,
} from '../../../../../helpers/localStorage';
import { StorageValues } from '../../../../../interfaces/storage';
import { WebsocketAction } from '../../../../../interfaces/webSocketConnectionInfo';

const { REACT_APP_AI_API_URL } = process.env;

export const PocAiContext = createContext<IPocAiContext>({} as IPocAiContext);

export const usePocAi = () => useContext(PocAiContext);

const socket = io(REACT_APP_AI_API_URL);
let audioContext: any;
let audioInput: any;
let processor: any;
let visualizerInstance: any;
// TODO: temp workaround, do a proper fix on SM side to have an option to use Transcibe only
let activeScreenGlob = POC_SCREENS.DEFAULT;
// TODO: temp workaround
let isMutedGlob = false;
let audioPlayBackType = '';
let currentRegion = '';

export const PocAiProvider: FC<PocAiProviderProps> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [awaitingResponse, setAwaitingResponse] = useState(false);
  const [activeScreen, setActiveScreen] = useState<POC_SCREENS>(
    POC_SCREENS.DEFAULT,
  );
  const [navigationHistory, setNavigationHistory] = useState<POC_SCREENS[]>([
    POC_SCREENS.DEFAULT,
  ]);
  //const [audioPlayBackType, setAudioPlayBackType] = useState<EAudioPlaybackType>();
  let userName = '';

  const [chatHistory, setChatHistory] = useState<IPocMessage[]>([]);

  const [audioPromptHistory, setAudioPromptHistory] = useState<string[]>([]);

  const [isConnected, setIsConnected] = useState(socket.connected);
  const [isRecording, setIsRecording] = useState(false);
  const [isMuted, setIsMutedLoc] = useState(false);

  const [region, setRegion] = useState({
    current: '',
    previous: '',
  });

  const { user, sendJSONMessageToWebSocket } = useMytaverse();
  const mytaverseEventProvider = useMytaverseEvent();

  if (user.firstName) {
    userName = user.firstName;
  } else if (user.name) {
    userName = user.name;
  }

  const setIsMuted = () => {
    setIsMutedLoc(!isMuted);
    isMutedGlob = !isMutedGlob;
  };

  const navigate = (screen: POC_SCREENS, navigateBack?: boolean) => {
    if (navigateBack) {
      activeScreenGlob = navigationHistory[navigationHistory.length - 2];
      setActiveScreen(navigationHistory[navigationHistory.length - 2]);
    } else {
      activeScreenGlob = screen;
      setActiveScreen(screen);
    }

    setNavigationHistory((prevState) => {
      if (navigateBack) {
        prevState.pop();
        return [...prevState];
      }

      return [...prevState, screen];
    });
  };

  const addMessage = useCallback(
    ({ text, type }: { text: string; type: PocMessageType }) => {
      setChatHistory((prevState) => {
        return [...prevState, { text, type, timestamp: Date.now() }];
      });
    },
    [],
  );

  const addAudioHistory = useCallback((text: string) => {
    setAudioPromptHistory((prevState) => {
      return [...prevState, text];
    });
  }, []);

  const handleSendRequest = useCallback(
    async (text: string) => {
      addMessage({ text, type: PocMessageType.USER });
      setAwaitingResponse(true);

      audioPlayBackType = EAudioPlaybackType.CHAT;
      socket.emit('queryBedrock', { text });
    },
    [addMessage],
  );

  const queryBedrockTest = () => {
    console.warn('queryBedrockTest clicked');
    socket.emit('queryBedrock', { text: 'what is aws?' });
  };

  const generateAudioPlaybackTest = () => {
    console.warn('generateAudioPlaybackTest clicked');
    socket.emit('generateAudio', { text: 'what is aws?' });
  };

  const generateGreetingsAudio = (isFirstTimeLogin?: boolean) => {
    audioPlayBackType = EAudioPlaybackType.GREETINGS;
    console.warn('generateGreetingsAudio clicked');
    socket.emit('generateAudio', {
      text: (isFirstTimeLogin ? greetingsText : greetingsTextSecondary).replace(
        '@userName@',
        userName ? ', ' + userName : '',
      ),
    });
  };

  const playBackOnFirstTime = () => {
    console.warn('playBackOnFirstTime');

    const assistantStorage = getLocalStorageValue<any>(
      StorageValues.Assistant,
    ) || {
      isFirstTime: true,
    };

    if (assistantStorage.isFirstTime) {
      setOpen(true);

      setLocalStorageValue(StorageValues.Assistant, {
        isFirstTime: false,
      });

      generateGreetingsAudio(true);
    }

    // TEMP fix for auditorium exit button issue
    // when assistant 2nd login greetinggs is replayed
    // TODO: proper fix
    const assistantStorageSession = getLocalStorageValue<any>(
      StorageValues.Assistant,
      'session',
    ) || {
      isGreetingsPlayed: false,
    };

    if (!assistantStorageSession.isGreetingsPlayed) {
      setOpen(true);

      setLocalStorageValue(
        StorageValues.Assistant,
        {
          ...assistantStorageSession,
          isGreetingsPlayed: true,
        },
        'session',
      );

      generateGreetingsAudio(false);
    }
  };

  const startRecording = async () => {
    console.log('start recording audio');

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      console.log('microphone access granted');
      audioContext = new AudioContext();
      audioInput = audioContext.createMediaStreamSource(stream);
      processor = audioContext.createScriptProcessor(1024, 1, 1);
      audioInput.connect(processor);
      processor.connect(audioContext.destination);

      processor.onaudioprocess = (e: any) => {
        const float32Array = e.inputBuffer.getChannelData(0);
        const int16Array = new Int16Array(float32Array.length);
        for (let i = 0; i < float32Array.length; i++) {
          int16Array[i] = Math.max(
            -32768,
            Math.min(32767, Math.floor(float32Array[i] * 32768)),
          );
        }
        console.log(
          'sending audio chunk to server, size',
          int16Array.buffer.byteLength,
        );
        socket.emit('transcribeAudioData', int16Array.buffer);
      };

      socket.emit('startTranscription');
      console.log('startTranscription event emitted');
    } catch (error) {
      console.error('error accessing microphone', error);
    }
  };

  function stopRecording() {
    console.log('stop recording audio');

    if (audioContext && audioContext.state !== 'closed') {
      try {
        audioInput.disconnect();
        processor.disconnect();
        audioContext.close();
        socket.emit('stopTranscription');
      } catch (error) {
        console.error('error closing audio', error);
      }
    }
  }

  function stopPlayback() {
    visualizerInstance.stopAudio();
  }

  function isAIEnabledForEvent() {
    if (
      mytaverseEventProvider &&
      mytaverseEventProvider.currentEventId &&
      mytaverseEventProvider.events.find(
        (x) => x.eventId === mytaverseEventProvider.currentEventId,
      )?.enableAssistant
    ) {
      return true;
    }

    return false;
  }

  if (mytaverseEventProvider) {
    useEffect(() => {
      console.warn('mytaverseEventProvider', mytaverseEventProvider);
      setRegion({
        previous: region.current,
        current: mytaverseEventProvider.currentRegion?.region || '',
      });
      currentRegion = mytaverseEventProvider.currentRegion?.region || '';

      const assistantStorage = getLocalStorageValue<any>(
        StorageValues.Assistant,
        'session',
      ) || {
        audioZones: [],
      };

      if (assistantStorage && !assistantStorage.hasOwnProperty('audioZones')) {
        assistantStorage.audioZones = [];
      }

      if (
        mytaverseEventProvider.currentRegion?.region &&
        audioZoneScript[mytaverseEventProvider.currentRegion?.region] &&
        assistantStorage.audioZones.indexOf(
          mytaverseEventProvider.currentRegion?.region,
        ) === -1
      ) {
        audioPlayBackType = EAudioPlaybackType.AUDIO_ZONE;
        assistantStorage.audioZones.push(
          mytaverseEventProvider.currentRegion?.region,
        );
        setLocalStorageValue(
          StorageValues.Assistant,
          assistantStorage,
          'session',
        );

        console.warn(
          'generate audio for audio zone',
          mytaverseEventProvider.currentRegion?.region,
        );
        console.warn(
          'audio zone script',
          audioZoneScript[mytaverseEventProvider.currentRegion?.region],
        );

        if (!open) {
          setOpen(true);
        }

        socket.emit('generateAudio', {
          text: audioZoneScript[
            mytaverseEventProvider.currentRegion?.region
          ].replace('@userName@', userName ? ', ' + userName : ''),
        });
      }
    }, [mytaverseEventProvider.currentRegion]);
  }

  // simulate N key in GameCast
  function testKeyboard() {
    const enterEvent = new KeyboardEvent('keydown', keys.teleport);
    mytaverseEventProvider.gameCastRef.current.detachInput();
    mytaverseEventProvider.gameCastRef.current.attachInput();
    mytaverseEventProvider.gameCastRef.current.processKeyboardEvent(enterEvent);
  }

  useEffect(() => {
    console.warn('region has changed', region);
  }, [region]);

  function audioEndCallback() {
    console.warn('audioEndCallback', { audioPlayBackType, currentRegion });

    if (currentRegion === celebratoryMomentAudioZone) {
      console.warn('trigger celebratory moment websocket message');

      const wsMessage = {
        action: WebsocketAction.MytaverseAction,
        tag: 'MytaEvent.Action.CelebrationMoment.Start',
        data: '',
      };
      console.warn('sendJSONMessageToWebSocket', wsMessage);

      sendJSONMessageToWebSocket(wsMessage);
    }

    if (audioPlayBackType === EAudioPlaybackType.AUDIO_ZONE) {
      setOpen(false);
    }

    if (audioPlayBackType === EAudioPlaybackType.GREETINGS) {
      const assistantStorage = getLocalStorageValue<any>(
        StorageValues.Assistant,
      ) || {
        isFirstTime: true,
      };

      if (!assistantStorage.isFirstTime) {
        setOpen(false);
      }
    }
  }

  function teleportToRegion(regionName: string) {
    console.warn('teleport region', regionName);

    const wsMessage = {
      action: WebsocketAction.MytaverseAction,
      tag: 'MytaEvent.Action.Teleport.ToRegion',
      data: regionName,
    };
    console.warn('sendJSONMessageToWebSocket', wsMessage);

    sendJSONMessageToWebSocket(wsMessage);
  }

  function sendWSMessage(message: string) {
    console.warn('sendWSMessage', message);

    const wsMessage = JSON.parse(message);

    console.warn('sendJSONMessageToWebSocket', wsMessage);

    sendJSONMessageToWebSocket(wsMessage);
  }

  useEffect(() => {
    function onConnect() {
      console.warn('connected to AI POC');
      setIsConnected(true);
    }

    function onDisconnect() {
      setIsConnected(false);
    }

    function onQueryBedrock(data: any) {
      console.warn('activeScreen', activeScreenGlob);

      if (activeScreenGlob !== POC_SCREENS.DEFAULT) {
        console.warn('onQueryBedrock', data);
        setAwaitingResponse(false);
        socket.emit('generateAudio', { text: data });
        addMessage({ text: data, type: PocMessageType.ASSISTANT });
      }
    }

    function onGenerateAudio(data: any) {
      console.warn('activeScreen', activeScreenGlob);
      console.warn('isMutedGlob', isMutedGlob);

      if (/*activeScreenGlob !== POC_SCREENS.DEFAULT &&*/ !isMutedGlob) {
        console.warn('onGenerateAudio data len', data.length);

        if (visualizerInstance) {
          visualizerInstance.stopAudio();
        }
        visualizerInstance = visualizeAudio(
          assistantSize.width,
          assistantSize.height,
          audioEndCallback,
        );
        visualizerInstance.initialize(data);
      }
    }

    function onTranscription(data: any) {
      console.log('onTranscription', data);

      if (data.isFinal) {
        stopRecording();
        setIsRecording(false);

        if (activeScreenGlob === POC_SCREENS.DEFAULT) {
          const actionsTranscribePromptResult = processActionsAudioPrompt(
            data.text.toLowerCase(),
          );

          console.warn('actions prompt', {
            prompt: data.text,
            result: actionsTranscribePromptResult,
          });

          if (actionsTranscribePromptResult) {
            audioPlayBackType = EAudioPlaybackType.VOICE_COMMAND;

            if (actionsTranscribePromptResult.indexOf('expression') > -1) {
              const enterEvent = new KeyboardEvent(
                'keydown',
                keys[actionsTranscribePromptResult],
              );
              mytaverseEventProvider.gameCastRef.current.detachInput();
              mytaverseEventProvider.gameCastRef.current.attachInput();
              mytaverseEventProvider.gameCastRef.current.processKeyboardEvent(
                enterEvent,
              );
            } else if (actionsTranscribePromptResult.indexOf('teleport') > -1) {
              for (let i = 0; i < teleportPlaces.length; i++) {
                if (
                  actionsTranscribePromptResult.indexOf(teleportPlaces[i]) > -1
                ) {
                  const teleportRegion = teleportPlaces[i]
                    .replace('second', '2nd')
                    .replace('third', '3rd');

                  teleportToRegion(teleportRegion);
                  break;
                }
              }
            }

            addAudioHistory(data.text);
          } else {
            // playback default audio reply
            socket.emit('generateAudio', { text: defaultBedrockReply });
            navigate(POC_SCREENS.TELEPORT);
          }
        } else {
          addMessage({ text: data.text, type: PocMessageType.USER });
        }
      }
    }

    function processActionsAudioPrompt(text: string) {
      const actionTypes = [
        actionsPromptOptionsExpression,
        actionsPromptOptionsTeleport,
      ];
      let match = '';

      for (let a = 0; a < actionTypes.length; a++) {
        const actions = actionTypes[a];

        for (let i = 0; i < actions.length; i++) {
          const partial = actions[i].split(' ');
          let occurencies = [];

          // console.log(partial);

          for (let j = 0; j < partial.length; j++) {
            if (text.indexOf(partial[j]) > -1) {
              occurencies.push(partial[j]);
            }
          }

          if (occurencies.join(' ') === actions[i]) {
            console.log('match!', actions[i], text);
            match = actions[i];
          }
        }
      }

      return match;
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('queryBedrock', onQueryBedrock);
    socket.on('generateAudio', onGenerateAudio);
    socket.on('transcription', onTranscription);

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('queryBedrock', onQueryBedrock);
      socket.off('generateAudio', onGenerateAudio);
      socket.off('transcription', onTranscription);
    };
  }, []);

  return (
    <PocAiContext.Provider
      value={{
        activeScreen,
        setActiveScreen,
        open,
        setOpen,
        handleSendRequest,
        chatHistory,
        queryBedrockTest,
        generateAudioPlaybackTest,
        generateGreetingsAudio,
        playBackOnFirstTime,
        startRecording,
        stopRecording,
        navigate,
        setNavigationHistory,
        awaitingResponse,
        stopPlayback,
        isAIEnabledForEvent,
        isRecording,
        setIsRecording,
        audioPromptHistory,
        testKeyboard,
        isMuted,
        setIsMuted,
        teleportToRegion,
        sendWSMessage,
      }}
    >
      {children}
    </PocAiContext.Provider>
  );
};
