// ref: https://codepen.io/noeldelgado/pen/EaNjBy
export const visualizeAudio = (
  width = 128,
  height = 128,
  onAudioEndCallback,
) => {
  var fftSize = 1024,
    // [32, 64, 128, 256, 512, 1024, 2048]

    // much math wow
    TOTAL_POINTS = fftSize / 2,
    points = [],
    audio_buffer = [],
    avg_circle,
    bubble_avg_color = 'rgba(29, 36, 57, 0.1)', // dark blue
    bubble_avg_color_2 = 'rgba(29, 36, 57, 0.05)',
    bubble_avg_line_color = 'rgba(255, 255, 255, 1)',
    bubble_avg_line_color_2 = 'rgba(77, 218, 248, 1)',
    AVG_BREAK_POINT = 100,
    AVG_BREAK_POINT_HIT = 0,
    SHOW_AVERAGE = true,
    AudioContext = window.AudioContext || window.webkitAudioContext,
    sin = Math.sin,
    cos = Math.cos,
    PI = Math.PI,
    PI_TWO = PI * 2,
    PI_HALF = PI / 180,
    w = width * 2,
    h = height * 2,
    cx = w / 2,
    cy = h / 2,
    radiusDivisor = 5,
    playing = false,
    startedAt,
    pausedAt,
    avg,
    ctx,
    actx,
    asource,
    gainNode,
    analyser,
    frequencyData,
    frequencyDataLength,
    timeData;

  function initialize(stream) {
    if (!AudioContext) {
      console.log('audio playback is not supported');
    }

    var canvasElement = document.querySelector('#ai-assistant-canvas');
    ctx = canvasElement.getContext('2d');
    actx = new AudioContext();

    resizeHandler();
    initializeAudio(stream);
  }

  async function initializeAudio(stream) {
    console.log(stream);

    // play speech
    asource = actx.createBufferSource();

    actx.decodeAudioData(
      stream,
      function (buffer) {
        console.timeEnd('decoding audio data');

        audio_buffer = buffer;

        analyser = actx.createAnalyser();
        gainNode = actx.createGain();
        gainNode.gain.value = 1;

        analyser.fftSize = fftSize;
        analyser.minDecibels = -100;
        analyser.maxDecibels = -30;
        analyser.smoothingTimeConstant = 0.8;

        gainNode.connect(analyser);
        analyser.connect(actx.destination);

        frequencyDataLength = analyser.frequencyBinCount;
        frequencyData = new Uint8Array(frequencyDataLength);
        timeData = new Uint8Array(frequencyDataLength);

        createPoints();
        playAudio();
      },
      function (e) {
        console.error('Error decoding audio data', e);
      },
    );
  }

  function playAudio() {
    playing = true;
    startedAt = pausedAt ? Date.now() - pausedAt : Date.now();
    asource = null;
    asource = actx.createBufferSource();
    asource.addEventListener('ended', onAudioEnd);
    asource.buffer = audio_buffer;
    asource.loop = false;
    asource.connect(gainNode);
    pausedAt ? asource.start(0, pausedAt / 1000) : asource.start();

    // TODO: stop render when there is no audio
    animate();
  }

  function pauseAudio() {
    playing = false;
    pausedAt = Date.now() - startedAt;
    asource.stop();
  }

  function stopAudio() {
    try {
      playing = false;
      actx.close();
      asource.stop();
      clearCanvas();
    } catch (error) {
      console.log('error while stopping audio visualizer playback', error);
    }
  }

  function onAudioEnd() {
    console.warn('audio ended');

    if (onAudioEndCallback) {
      onAudioEndCallback();
    }
  }

  function getAvg(values) {
    var value = 0;

    values.forEach(function (v) {
      value += v;
    });

    return value / values.length;
  }

  function animate() {
    if (!playing) return;

    window.requestAnimationFrame(animate);
    analyser.getByteFrequencyData(frequencyData);
    analyser.getByteTimeDomainData(timeData);
    avg = getAvg([].slice.call(frequencyData)) * gainNode.gain.value;
    AVG_BREAK_POINT_HIT = avg > AVG_BREAK_POINT;

    clearCanvas();

    if (SHOW_AVERAGE) {
      drawAverageCircle();
      // attempt to draw assistant avatar into canvas
      // doesn't look too good, blurry and pixelated
      // var avatarElement = document.querySelector('#assistant-avatar');
      // ctx.drawImage(avatarElement, 0, 0, 2048, 2048, 32, 32, 64, 64);
    }
  }

  function clearCanvas() {
    ctx.beginPath();
    ctx.globalCompositeOperation = 'source-over';
    ctx.clearRect(0, 0, w, h);
  }

  function drawAverageCircle() {
    // TODO: what is AVG_BREAK_POINT_HIT?
    if (AVG_BREAK_POINT_HIT) {
      ctx.strokeStyle = bubble_avg_line_color_2;
      ctx.fillStyle = bubble_avg_color_2;
    } else {
      ctx.strokeStyle = bubble_avg_line_color;
      ctx.fillStyle = bubble_avg_color;
    }

    ctx.beginPath();
    ctx.lineWidth = 2; // circle line width

    ctx.arc(cx, cy, avg + avg_circle.radius, 0, PI_TWO, false);

    ctx.stroke();
    ctx.fill();
    ctx.closePath();
  }

  function Point(config) {
    this.index = config.index;
    this.angle = (this.index * 360) / TOTAL_POINTS;

    this.updateDynamics = function () {
      this.radius = Math.abs(w, h) / radiusDivisor; // circle radius
      this.x = cx + this.radius * sin(PI_HALF * this.angle);
      this.y = cy + this.radius * cos(PI_HALF * this.angle);
    };

    this.updateDynamics();

    this.value = Math.random() * 256;
    this.dx = this.x + this.value * sin(PI_HALF * this.angle);
    this.dy = this.y + this.value * cos(PI_HALF * this.angle);
  }

  function AvgCircle() {
    this.update = function () {
      this.radius = Math.abs(w, h) / radiusDivisor; // circle radius
    };

    this.update();
  }

  function createPoints() {
    var i;

    i = -1;
    while (++i < TOTAL_POINTS) {
      points.push(new Point({ index: i + 1 }));
    }

    avg_circle = new AvgCircle();

    i = null;
  }

  function resizeHandler() {
    ctx.canvas.width = w;
    ctx.canvas.height = h;

    points.forEach(function (p) {
      p.updateDynamics();
    });

    if (avg_circle) {
      avg_circle.update();
    }
  }

  return {
    initialize,
    stopAudio,
  };
};
