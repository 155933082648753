import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getNotification } from '../components/Notification/helpers';

import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../components/Notification';
import { sendCustomSentryBreadcrumb } from '../helpers/sentry';

import { MeasurementConfig } from '@cloudflare/speedtest';
import SpeedTest from '@cloudflare/speedtest';

import { getIsLowInternetSpeed } from '../helpers';
import { convertBitToMB } from '../modules/auth/components/ConnectionTest/helpers';
import { StorageTypes } from '../interfaces/storage';

// use short bursts to measure speed in game
// we don't want to use too much of b/w for periodic speed checks
// especially on mobile connections
const MeasurementOptions: MeasurementConfig[] = [
  { type: 'download', bytes: 1e5, count: 1, bypassMinDuration: true }, // initial download estimation
  { type: 'download', bytes: 1e5, count: 9 },
];

const useSpeedTestTimer = () => {
  const { t: translate } = useTranslation('common');

  const [speed, setSpeed] = useState(0);
  const [isCompleted, setIsCompleted] = useState(false);

  const { showNotification } = useNotification();

  const runSpeedTest = () => {
    setIsCompleted(false);

    const speedResult = new SpeedTest({
      measureUploadLoadedLatency: false,
      measureDownloadLoadedLatency: false,
      measurements: MeasurementOptions,
    });

    speedResult.onFinish = (results) => {
      setSpeed(convertBitToMB(results.getDownloadBandwidth() ?? 0));
      setIsCompleted(true);
    };

    speedResult.onResultsChange = () => {
      setSpeed(convertBitToMB(speedResult.results.getDownloadBandwidth() ?? 0));
    };
  };

  const processSpeedTestResult = (speed: number) => {
    const isLowInternetSpeed = getIsLowInternetSpeed(speed);
    if (!isLowInternetSpeed) return;

    const message = `Internet connection speed is ${speed} Mbps`;

    const notificationData = getNotification({
      message: translate('notifications.unstableInternetTimer', {
        speed: process.env.REACT_APP_MIN_SPEED_THRESHOLD_MBPS || '15',
      }),
      type: NOTIFICATION_TYPES.WARNING,
    });

    showNotification({
      ...notificationData,
      logMessage: message,
      storageType: StorageTypes.Session,
    });

    sendCustomSentryBreadcrumb({ message });
  };

  useEffect(() => {
    runSpeedTest();

    const interval = setInterval(() => {
      runSpeedTest();
    }, +(process.env.REACT_APP_SPEED_CHECK_TIMEOUT_MINS || 10) * 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!isCompleted) return;

    processSpeedTestResult(speed);
  }, [isCompleted, speed]);
};

export default useSpeedTestTimer;
