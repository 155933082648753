import axios, { AxiosResponse } from 'axios';
import { camelCase } from 'lodash';
import { BadRequest } from '../helpers/error';
import { IAvatarSkin } from '../interfaces/avatarSkin';

import {
  IEvent,
  IFollowingDataResponse,
  IGetStreamingProviderResponse,
  PlayerSession,
} from '../interfaces/event';
import { IEventSubscribe } from '../interfaces/eventSubscribe';
import { IMillicastTokens } from '../interfaces/millicastTokens';
import {
  IParticipant,
  IParticipantInfo,
  IParticipantRoles,
  ParticipantState,
} from '../interfaces/participants';
import { IPointOfInterest } from '../interfaces/pointsOfInterest';
import { ICustomStyles } from '../interfaces';
import {
  IAcceptFollowingInvitationParams,
  IDeclineFollowingInvitationParams,
  ISendFollowingInvitationParams,
} from './interfaces';
import { nanoid } from 'nanoid';
import { getFullUserName } from '../helpers/user';
import { IDolbyMediaStream } from '../modules/dashboard/components/DashboardContent/Dolby/interfaces';
import { IEventParticipant } from '../interfaces/eventParticipant';

const { REACT_APP_API_URL, REACT_APP_SPATIAL_MANAGER_API_URL } = process.env;

export default class EventsService {
  static getEvents(): Promise<IEvent[]> {
    return axios
      .get(`${REACT_APP_API_URL}cms/events/v4/subscribed`)
      .then((res: any) => res.data.subscribedEvents)
      .catch(() => {
        throw new BadRequest(new Error('Application error'));
      });
  }

  static getEvent(eventId: string): Promise<IEventParticipant> {
    return axios
      .get(
        `${REACT_APP_SPATIAL_MANAGER_API_URL}api/v1/profile/events/${eventId}`,
      )
      .then((res: any) => res.data)
      .catch(() => {
        throw new BadRequest(new Error(`Can't load event data`));
      });
  }

  static prepareParticipant(participant: IParticipant): IParticipant {
    // TODO save firstName lastName from social login
    const fullName = getFullUserName({
      firstName: participant.email.split('@')[0],
      lastName: participant.email.split('@')[2],
    });
    const avatarString = fullName
      .split(' ')
      .map((word) => (word.length !== 0 ? word[0].toUpperCase() : ''))
      .join('');

    return {
      participantId: participant.participantId,
      key: nanoid(10),
      email: participant.email,
      name: participant.name,
      firstName: participant.email.split('@')[0],
      lastName: participant.email.split('@')[2],
      fullName,
      avatarString,
      avatarImage: participant.avatarImage,
      isSpeaker: participant.isSpeaker,
      region: null,
      regions: [],
      state: ParticipantState.Loaded,
      isDemoUser: participant.isDemoUser,
      streamChatMemberId: participant.streamChatMemberId,
    };
  }

  static getEventParticipants(eventId: string): Promise<IParticipant[]> {
    return axios
      .get(`${REACT_APP_API_URL}cms/events/participants/${eventId}`)
      .then((res: AxiosResponse<{ subscribers: IParticipant[] }>) => {
        const { subscribers } = res.data;
        const participants = subscribers.reduce<IParticipant[]>(
          (participantsList, participant) => {
            if (!participant.participantId) {
              return participantsList;
            }

            const mergingParticipant = this.prepareParticipant(participant);

            return [...participantsList, mergingParticipant];
          },
          [],
        );

        return participants;
      })
      .catch(() => {
        throw new BadRequest(new Error(`Can't load event participants`));
      });
  }

  static getEventConnections(eventId: string): Promise<IParticipantInfo[]> {
    return axios
      .get(
        `${REACT_APP_SPATIAL_MANAGER_API_URL}api/v2/events/${eventId}/connections`,
      )
      .then((res: any) => res.data)
      .catch(() => {
        throw new BadRequest(new Error(`Can't load participant rooms`));
      });
  }

  static getEventParticipantsRoles(
    eventId: string,
  ): Promise<IParticipantRoles> {
    return axios
      .get(`${REACT_APP_SPATIAL_MANAGER_API_URL}api/v2/events/${eventId}/roles`)
      .then((res: any) => res.data)
      .catch(() => {
        throw new BadRequest(new Error(`Can't load participant roles`));
      });
  }

  static getParticipantProfile(userId: string): Promise<IParticipant> {
    return axios
      .get(`${REACT_APP_SPATIAL_MANAGER_API_URL}api/v2/participants/${userId}`)
      .then((res: any) => {
        return {
          userId,
          ...res.data,
        };
      });
  }

  static getParticipant(participantId: string): Promise<IParticipant> {
    return axios
      .get(
        `${REACT_APP_SPATIAL_MANAGER_API_URL}api/v2/participants/${participantId}`,
      )
      .then((res: any) => {
        return {
          ...res.data,
          key: nanoid(10),
          state: ParticipantState.Loaded,
        };
      });
  }

  static updateRoomSpatialScale(
    roomId: string,
    scale: number,
    eventId: string,
  ) {
    return axios.patch(
      `${REACT_APP_SPATIAL_MANAGER_API_URL}api/v1/rooms/${roomId}`,
      {
        dolbySpatialAudioScale: scale,
        eventId,
      },
    );
  }

  static getMillicastStreamName(
    roomId: string,
    region: string,
    screenName: string,
  ): string {
    return [roomId, region, screenName]
      .filter((s) => !!s)
      .map((s) => camelCase(s))
      .join('-');
  }

  static getCustomerEvent(customerId: string): Promise<ICustomStyles> {
    return axios
      .get(`${REACT_APP_SPATIAL_MANAGER_API_URL}api/v1/customers/${customerId}`)
      .then((res: any) => res.data);
  }

  static startStreamVideo(data: any): Promise<IMillicastTokens> {
    return axios
      .post(
        `${REACT_APP_SPATIAL_MANAGER_API_URL}api/v1/millicast/streams/${data.streamName}/start`,
        data,
      )
      .then((res: any) => res.data)
      .catch(() => {
        throw new Error("Can't start millicast video");
      });
  }

  static stopStreamVideo(streamName: string): Promise<IMillicastTokens> {
    return axios
      .delete(
        `${REACT_APP_SPATIAL_MANAGER_API_URL}api/v1/millicast/streams/${streamName}/stop`,
      )
      .then((res: any) => res.data)
      .catch(() => {
        throw new Error("Can't stop millicast video");
      });
  }

  static getPointOfInterest(roomId: string): Promise<IPointOfInterest[]> {
    return axios
      .get(
        `${REACT_APP_API_URL}/api/v2/rooms/${roomId}/spots/custom?isPoi=true`,
      )
      .then((res: any) => res.data.roomSpots);
  }

  static confirmEvent(payload: IEventSubscribe): Promise<boolean> {
    return axios
      .post('auth/subscribeConfirm', payload)
      .then((res: any) => !!res.data?.result);
  }

  static setEventUserData(eventId: string, data: IEventParticipant) {
    return axios
      .patch(
        `${REACT_APP_SPATIAL_MANAGER_API_URL}api/v1/profile/events/${eventId}`,
        data,
      )
      .then((res: any) => res.data);
  }

  static getEventStreamingProvider(
    eventId: string,
  ): Promise<IGetStreamingProviderResponse> {
    return axios
      .get(
        `${REACT_APP_SPATIAL_MANAGER_API_URL}api/v2/events/${eventId}/streaming-provider`,
      )
      .then((res: any) => res.data)
      .catch(() => null);
  }

  static getFollowingData(eventId: string): Promise<IFollowingDataResponse> {
    return axios
      .get(
        `${REACT_APP_SPATIAL_MANAGER_API_URL}api/v2/events/${eventId}/following`,
      )
      .then((res: any) => res.data);
  }

  static sendFollowingInvitation({
    eventId,
    ...payload
  }: ISendFollowingInvitationParams): Promise<void> {
    return axios
      .post(
        `${REACT_APP_SPATIAL_MANAGER_API_URL}api/v2/events/${eventId}/following`,
        payload,
      )
      .then((res: any) => res.data);
  }

  static acceptFollowingInvitation({
    eventId,
    ...payload
  }: IAcceptFollowingInvitationParams): Promise<void> {
    return axios
      .post(
        `${REACT_APP_SPATIAL_MANAGER_API_URL}api/v2/events/${eventId}/following/accept`,
        payload,
      )
      .then((res: any) => res.data);
  }

  static declineFollowingInvitation({
    eventId,
    ...payload
  }: IDeclineFollowingInvitationParams): Promise<void> {
    return axios
      .post(
        `${REACT_APP_SPATIAL_MANAGER_API_URL}api/v2/events/${eventId}/following/decline`,
        payload,
      )
      .then((res: any) => res.data);
  }

  static createDolbyMediaStream(data: IDolbyMediaStream): Promise<any> {
    return axios
      .post(
        `${REACT_APP_SPATIAL_MANAGER_API_URL}api/v2/dolby/media-streams/${data.mediaStreamId}`,
        data,
      )
      .then((res: any) => res.data)
      .catch(() => {
        throw new Error("Can't create dolby media stream");
      });
  }

  static deleteDolbyMediaStream(data: any): Promise<any> {
    return axios
      .delete(
        `${REACT_APP_SPATIAL_MANAGER_API_URL}api/v2/dolby/media-streams/${data.mediaStreamId}`,
      )
      .then((res: any) => res.data)
      .catch(() => {
        throw new Error("Can't create dolby media stream");
      });
  }

  static inviteParticipant(eventId: string, email: string): Promise<any> {
    return axios
      .post(
        `${REACT_APP_SPATIAL_MANAGER_API_URL}api/v2/events/${eventId}/participants?iXRHRData=false`,
        {
          emails: [email],
          staffIds: [],
        },
      )
      .then((res: any) => res.data)
      .catch(() => {
        throw new Error("Can't create dolby media stream");
      });
  }

  static createGameliftPlayerSession(
    eventId: string,
    roomId: string,
  ): Promise<PlayerSession | null> {
    return axios
      .post(
        `${REACT_APP_SPATIAL_MANAGER_API_URL}api/v2/events/${eventId}/gamelift-player-session`,
        {
          roomId,
        },
      )
      .then((res: any) => res.data)
      .catch(() => {
        throw new Error("Can't create gamelift player session");
      });
  }
}
