import { getCatchErrorMessage } from './error';

import { StorageValues, StorageTypes } from '../interfaces/storage';
import { SessionStorage } from '../constants/storage';

export const getStorageValue = <T>(
  key: StorageValues,
  storageType: StorageTypes = StorageTypes.Local,
): T | null => {
  let item: string | null;

  try {
    switch (storageType) {
      case StorageTypes.Session:
        item = window.sessionStorage.getItem(key);
        break;
      default:
        item = window.localStorage.getItem(key);
    }

    return item ? (JSON.parse(item) as T) : null;
  } catch (error) {
    throw Error(getCatchErrorMessage(error));
  }
};

export const setStorageValue = (
  key: StorageValues,
  value: unknown,
  storageType: StorageTypes = StorageTypes.Local,
) => {
  try {
    switch (storageType) {
      case StorageTypes.Session:
        window.sessionStorage.setItem(key, JSON.stringify(value));
        break;
      default:
        window.localStorage.setItem(key, JSON.stringify(value));
    }
  } catch (error) {
    throw Error(getCatchErrorMessage(error));
  }
};

export const removeLocalStorageValue = (key: StorageValues) => {
  try {
    window.localStorage.removeItem(key);
  } catch (error) {
    throw Error(getCatchErrorMessage(error));
  }
};
