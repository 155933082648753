import { useTheme } from '@mui/material';
import { colors } from '../../../../../constants/colors';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    box: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 1,
      padding: 2,
      borderRadius: 2,
      background: '#00000066',
      textTransform: 'none',
    },
    icon: {
      borderRadius: '50%',
      padding: 2,
      background: '#FFFFFF0D',
      border: '1px solid white',

      '& svg': {
        color: colors.white,
        width: 32,
        height: 32,
        display: 'block',
      },
    },
    title: {
      textTransform: 'capitalize',
      color: colors.white,
      fontSize: 14,
      fontWeight: 500,
      lineHeight: 1.5,
    },
  };
};
