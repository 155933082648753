import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useNotification } from '../../../../../components/Notification';

import { getCatchErrorMessage } from '../../../../../helpers/error';

import {
  InviteUserToEventType,
  UseInviteUserToEventType,
  UseOpenInviteUserToEventModalType,
} from './interfaces';
import { useMytaverseEvent } from '../../../providers';
import EventsService from '../../../../../services/EventsService';

export const useOpenInviteUserToEventModal: UseOpenInviteUserToEventModalType =
  () => {
    const [open, setOpen] = useState(false);

    const openModal = () => setOpen(true);

    const closeModal = () => setOpen(false);

    return { open, openModal, closeModal };
  };

export const useInviteUserToEvent: UseInviteUserToEventType = (onClose) => {
  const { t: translate } = useTranslation('common');
  const { currentEventId } = useMytaverseEvent();
  const {
    showNotification,
    getSuccessNotification,
    getBadRequestNotification,
  } = useNotification();

  const inviteUserToEvent: InviteUserToEventType = async (email) => {
    try {
      await EventsService.inviteParticipant(currentEventId, email);

      showNotification(
        getSuccessNotification({
          message: translate('inviteUserModal.successText'),
        }),
      );

      onClose();
    } catch (error: unknown) {
      const errorMessage = getCatchErrorMessage(error);

      showNotification(getBadRequestNotification({ message: errorMessage }));
      throw Error(getCatchErrorMessage(error));
    }
  };

  return inviteUserToEvent;
};
