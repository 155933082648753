import React, { useRef, useEffect } from 'react';
import { Box } from '@mui/material';
import SmsIcon from '@mui/icons-material/Sms';

import { usePocAi } from '../providers/PocAiProvider';
import Message from './Message';

import { useStyles } from './styles';

const ChatBox = () => {
  const classes = useStyles();
  const { chatHistory, awaitingResponse } = usePocAi();
  const messagesEndRef = useRef<null | HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatHistory]);

  return (
    <Box sx={classes.box}>
      {chatHistory.map((message) => (
        <Message key={message.timestamp} message={message} />
      ))}
      {awaitingResponse && <SmsIcon sx={classes.smsIcon} />}
      <div ref={messagesEndRef} />
    </Box>
  );
};

export default ChatBox;
