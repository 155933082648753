import React, { useCallback } from 'react';
import { Button, Typography } from '@mui/material';

import { useStyles } from './styles';
import { usePocAi } from '../providers/PocAiProvider';
import { POC_SCREENS } from '../constants';

interface IQuestionBox {
  title: string;
}

const QuestionBox = ({ title }: IQuestionBox) => {
  const classes = useStyles();

  const { setOpen, setNavigationHistory, navigate, teleportToRegion } =
    usePocAi();

  const handleClick = useCallback(() => {
    teleportToRegion(title);
    setOpen(false);
    setNavigationHistory([]);
    navigate(POC_SCREENS.DEFAULT);
  }, [title]);

  return (
    <Button onClick={handleClick} sx={classes.box}>
      <Typography sx={classes.title}>{title}</Typography>
    </Button>
  );
};

export default QuestionBox;
