export enum POC_SCREENS {
  DEFAULT = 'DEFAULT',
  HOW_TO = 'HOW_TO',
  CHAT = 'CHAT',
  TELEPORT = 'TELEPORT',
}

export const assistantSize = {
  width: 192,
  height: 192,
};

// the following flags are for debug purproses
export const enableDebugControls = false;
export const enableOnEventSelectionpage = false;

export const greetingsText =
  'Hello @userName@. Welcome to the metaverse. My name is Noa and I am here to help you. You can always click on the assistant button on the bottom right to ask me any question you have. Please feel free to roam around and let me know if you need my help. You can ask me simple things like: "how do I walk in the metaverse" to very specific questions about your company and industry. I will do my best to help you in any way I can';
export const greetingsTextSecondary =
  'Welcome back @userName@. I am Noa, your AI assistant. Remember I am here to help. If you need me, you know where to find me.';
export const defaultBedrockReply =
  'I could not find an exact answer to the question. However, I found information that may be relevant.';

export const audioZoneScript: { [key: string]: string } = {
  // auditorium entrance
  bpSpotRegionEx01:
    'Hello, @userName@! Welcome to the AWS AI Certification Experience. I’m Noa, your Generative AI assistant powered by AWS technologies, including Bedrock, Nova, Polly, and Transcribe. Follow the 9 booths starting from the left of the floor, each covering a key topic. Let’s begin!',
  // auditorium booths
  bpSpotRegionExB01:
    'This booth explores the basics of AI and how it simulates human intelligence. Let’s dive in!',
  bpSpotRegionExB02:
    'Learn how AWS and cloud computing provide scalable, cost-effective IT solutions here.',
  bpSpotRegionExB03:
    'Discover Amazon Bedrock and how AWS powers Generative AI applications effortlessly.',
  bpSpotRegionExB04:
    'Explore how crafting the right prompts optimizes AI outputs in this booth.',
  bpSpotRegionExB05:
    'Learn about Amazon Q, a service that simplifies deploying AI solutions at scale.',
  bpSpotRegionExB06:
    'This booth covers AI, machine learning, and how they solve real-world problems.',
  bpSpotRegionExB07:
    'Explore AWS AI services like recognition and Transcribe for your business needs.',
  bpSpotRegionExB08:
    'Discover SageMaker, AWS tool for building and deploying machine learning models.',
  bpSpotRegionExB09:
    'Learn about ethical AI practices, security, and compliance to ensure responsible AI use.',
  // celebration moment
  bpSpotRegionExB910:
    'Congratulations, @userName@! You’ve completed the AWS AI Certification Experience. I’m thrilled to announce that you’ve successfully finished the program. You will now be teleported to the auditorium to receive your certification. Great job, and let’s move to your next destination!',
};

export const celebratoryMomentAudioZone = 'bpSpotRegionExB910';

export const teleportPlaces = [
  'auditorium',
  'board room',
  'beach',
  'cafe',
  'executive center',
  'executive center second floor',
  'executive center third floor',
  'exhibit hall',
  'garden',
  'marina',
];
export const actionsPromptOptionsTeleport = ['teleport', 'take me']
  .map((x) => {
    return teleportPlaces.map((y) => x + ' ' + y);
  })
  .flat(1);

// console.log('actionsPromptOptionsTeleport', actionsPromptOptionsTeleport);

export const actionsPromptOptionsExpression = [
  'expression no',
  'expression yes',
  'expression wave',
  'expression applaud',
];

export const keys: { [key: string]: any } = {
  'expression no': {
    key: 'n',
    code: 'KeyN',
    keyCode: 78,
  },
  'expression yes': {
    key: 'y',
    code: 'KeyY',
    keyCode: 84,
  },
  'expression wave': {
    key: '1',
    code: 'Digit1',
    keyCode: 49,
  },
  'expression applaud': {
    key: '2',
    code: 'Digit2',
    keyCode: 50,
  },
  teleport: {
    key: 't',
    code: 'KeyT',
    keyCode: 89,
  },
};

export const showMuteButtonOnHover = false;
