import { useTheme } from '@mui/material';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    box: {
      /*width: 184,
      height: 184,

      '& img': {
        width: '100%',
        height: 'auto',
        borderRadius: '50%',
        overflow: 'hidden',
        border: '1px solid white',
      },*/

      '& .assistant .assistant-avatar': {
        position: 'relative',
        borderRadius: '50%',
        overflow: 'hidden',
        border: '1px solid white',
        zIndex: '10',
      },

      '& .assistant.with-hover:hover .assistant-mute': {
        display: 'inherit',
      },

      '& .assistant.with-hover .assistant-mute': {
        display: 'none',
      },

      '& .assistant .ai-assistant-canvas': {
        position: 'absolute',
        marginLeft: '-288px',
        marginTop: '-96px',
      },

      '& .assistant .assistant-mute': {
        width: '40px',
        height: '40px',
        background: '#FFFFFF',
        border: '1px solid #FFFFFF',
        boxShadow: '0px 0px 2.7px 6px rgba(255, 255, 255, 0.5)',
        borderRadius: '50%',
        position: 'fixed',
        top: '158px',
        zIndex: '100',
        cursor: 'pointer',
      },

      '& .assistant .assistant-mute svg': {
        marginTop: '7px',
        marginLeft: '7px',
      },

      '& .assistant .assistant-mute.muted svg': {
        color: '#aa0707',
      },
    },
  };
};
