import React, { useEffect } from 'react';

import { Box } from '@mui/material';

import HomeBottomButtonOptions from './HomeBottomButtonOptions';
import ChatButton from './ChatButton';
import ExitButton from './ExitButton';
import Actions from './Actions';
import ToolBox from './ToolBox';

import { useConference } from '../DashboardContent/Dolby';
import { useMytaverseEvent } from '../../providers';

import { useStyles } from './styles';
import MytaverseAppBar from '../../../../components/MytaverseAppBar';
import VirtualAssistant from './VirtualAssistant/VirtualAssistant';
import ToggleScreenSharingView from './ToggleScreenSharingView';
import { PocAiButton, PocAiDashboard } from '../PocAi/index';
import { usePocAi } from '../PocAi';

const HomeBottomBar = () => {
  const classes = useStyles();

  const { videoStarted, toggleVideo } = useConference();
  const { currentRoom } = useMytaverseEvent();
  const { setOpen, isAIEnabledForEvent, playBackOnFirstTime } = usePocAi();

  const showDolbyConferenceControls = !!currentRoom;

  useEffect(() => {
    return () => {
      if (videoStarted) {
        toggleVideo();
      }
    };
  }, [videoStarted]);

  useEffect(() => {
    if (isAIEnabledForEvent()) {
      playBackOnFirstTime();
    }
  }, []);

  if (!showDolbyConferenceControls) {
    return null;
  }

  return (
    <>
      <MytaverseAppBar position="bottom" sx={classes.leftBar}>
        <Box sx={classes.section}>
          <HomeBottomButtonOptions />
        </Box>
      </MytaverseAppBar>
      <MytaverseAppBar position="bottom" sx={classes.rightBar}>
        <Box sx={classes.section}>
          <ToggleScreenSharingView />
          {isAIEnabledForEvent() && (
            <>
              <PocAiButton />
              <PocAiDashboard />
            </>
          )}
          {!isAIEnabledForEvent() && <VirtualAssistant />}
          <ToolBox />
          <Actions />
          {!!currentRoom?.isMultiPlayer ? <ChatButton /> : ''}
        </Box>
      </MytaverseAppBar>
      <MytaverseAppBar position="bottom" sx={classes.centerBar}>
        <ExitButton />
      </MytaverseAppBar>
    </>
  );
};

export default HomeBottomBar;
