import React, { useEffect, useRef, useState } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useMytaverse } from '../../../../../providers/MytaverseProvider';
import { useMytaverseEvent } from '../../../providers';
import { usePreventGameKeydownListening } from '../../../../../hooks';
import LoadingProgress from '../../../../../components/LoadingProgress';

const GenerateImageModal = () => {
  const { sendMessageToUnreal } = useMytaverse();
  const { currentRoom } = useMytaverseEvent();
  const inputRef = useRef<any>(null);
  const [processingImage, setProcessingImage] = useState<boolean>(false);
  const preventKeydownCallback = (event: Event) => {
    if ((event as KeyboardEvent).key !== 'Enter') {
      event.stopPropagation();
    }
  };
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  usePreventGameKeydownListening<HTMLTextAreaElement>(
    inputRef,
    preventKeydownCallback,
  );

  const handleSend = (values: { prompt: string }) => {
    if (currentRoom) {
      setProcessingImage(true);
      sendMessageToUnreal({
        action: 'GENERATE_IMAGE',
        prompt: values.prompt,
      });

      timeoutRef.current = setTimeout(() => {
        setProcessingImage(false);
      }, 25000);
    }
  };

  const formik = useFormik({
    initialValues: { prompt: '' },
    onSubmit: handleSend,
  });

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      setProcessingImage(false);
    }
  }, []);

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 100,
        left: 50,
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        padding: 2,
        borderRadius: 1,
        boxShadow: 3,
        zIndex: 1000,
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <TextField
          label="Image Prompt"
          name="prompt"
          variant="outlined"
          fullWidth
          margin="normal"
          ref={inputRef}
          value={formik.values.prompt}
          onChange={formik.handleChange}
        />
        <Button
          disabled={processingImage}
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
        >
          {processingImage ? (
            <LoadingProgress size={24} />
          ) : (
            <Typography>Send</Typography>
          )}
        </Button>
      </form>
    </Box>
  );
};

export default GenerateImageModal;
