import { IMytaverseFile } from './file';
import { IiXRHrData } from './profile';

export interface IUser {
  chatImage: string;
  confirmedEmail: number;
  email: string;
  name: string;
  firstName: string;
  lastName: string;
  id: string;
  profileImage: IMytaverseFile;
  showContactInfo?: string;
  userId?: string;
  company?: string;
  phoneNumber?: string;
  linkedIn?: string;
  isTermsAccepted?: boolean;
  isAvatarTermsAccepted: boolean | null;
  hrData: IiXRHrData;
  avaturnId?: string;
  customAvatarUrl?: string;
  roles?: string[]
}

export interface IUserCredentials {
  firstName?: string;
  lastName?: string;
  email: string;
  password: string;
}

export interface IAutoUserCredentials {
  token: string;
}

export interface ILoginUserResponse {
  user: IUser;
  token: string;
}

export enum LogoutReasonEnum {
  UNAUTHORISED = 'UNAUTHORISED',
  SESSION_EXPIRED = 'SESSION_EXPIRED',
}
