export enum PocMessageType {
  ASSISTANT = 'ASSISTANT',
  USER = 'USER',
}
export interface IPocMessage {
  text: string;
  type: PocMessageType;
  timestamp: number;
}

export enum EAudioPlaybackType {
  VOICE_COMMAND = 'VOICE_COMMAND',
  AUDIO_ZONE = 'AUDIO_ZONE',
  GREETINGS = 'GREETINGS',
  CHAT = 'CHAT'
}
