import { isEqual } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useAsyncEffect from 'use-async-effect/';
import { useNavigate } from 'react-router-dom';

import { IAvatarSkin } from '../../../../../interfaces/avatarSkin';
import { useMytaverse } from '../../../../../providers/MytaverseProvider';
import { useNotification } from '../../../../../components/Notification';
import EventsService from '../../../../../services/EventsService';
import {
  EventParticipantStateType,
  IEventParticipant,
} from '../../../../../interfaces/eventParticipant';
import ParticipantService from '../../../../../services/ParticipantsService';

import {
  EventParticipantDataType,
  InitMessageActions,
  ParticipantsStateHookProps,
  SetParticipantStateType,
  UseInitMessageProps,
} from '../interfaces';
import { getInitialMapParticipants } from '../../../../../helpers/participant';
import { IParticipant } from '../../../../../interfaces/participants';
import { WebsocketAction } from '../../../../../interfaces/webSocketConnectionInfo';
import { IInitMessage } from '../../../components/DashboardContent/Pureweb/interfaces';
import { ParticipantPosition } from '../../../components/DashboardContent/interfaces';
import { getSessionStorageValue } from '../../../../../helpers/sessionStorage';
import { isMobile } from 'react-device-detect';
import { SessionStorage } from '../../../../../constants/storage';

import { MytaverseLogger } from '../../../../../helpers/logger';
import ROUTES from '../../../../../constants/routes';
import { IEvent } from '../../../../../interfaces/event';
import {
  IXR_HR_BRAND_UNIFORMS,
  IXRHrCredStatusTypes,
} from '../../../../../interfaces/profile';
import { getDeviceProfile } from '../helpers';
import { useMytaverseEvent } from '../MytaverseEventProvider';

const GAME_SOUND = 0.5;
const PARTICIPANT_SOUND = 1;

export const useEventParticipantData = ({
  currentEvent,
  currentEventParticipant,
}: EventParticipantDataType) => {
  const { selectedLanguage, userId } = useMytaverse();

  const firstRenderSettings = useRef<IEventParticipant | null>(null);
  const settingsRequestTimeout = useRef<NodeJS.Timeout | null>(null);

  const [participantsSound, setParticipantsSound] = useState(PARTICIPANT_SOUND);
  const [gameSound, setGameSound] = useState(GAME_SOUND);

  useAsyncEffect(async () => {
    if (
      firstRenderSettings.current ||
      !currentEvent ||
      !currentEventParticipant
    ) {
      return;
    }

    const { participantId, selectedLanguage, participantsSound, gameSound } =
      currentEventParticipant;

    setParticipantsSound((participantsSound as number) || PARTICIPANT_SOUND);
    setGameSound((gameSound as number) || GAME_SOUND);

    firstRenderSettings.current = {
      eventId: currentEvent.eventId,
      participantId,
      selectedLanguage,
      participantsSound: (participantsSound as number) || PARTICIPANT_SOUND,
      gameSound: (gameSound as number) || GAME_SOUND,
    };
  }, [currentEventParticipant, currentEvent]);

  useEffect(() => {
    //TODO create request to set setting
    if (
      !currentEventParticipant ||
      !currentEvent ||
      !firstRenderSettings.current
    ) {
      return;
    }

    const isEqualSettings = isEqual(firstRenderSettings.current, {
      eventId: currentEvent.eventId,
      participantId: currentEventParticipant.participantId,
      selectedLanguage,
      participantsSound,
      gameSound,
    });

    if (isEqualSettings) {
      return;
    }

    const updateEventUserData = async () => {
      if (settingsRequestTimeout.current) {
        clearTimeout(settingsRequestTimeout.current);
        settingsRequestTimeout.current = null;
      }

      settingsRequestTimeout.current = setTimeout(async () => {
        firstRenderSettings.current = {
          eventId: currentEvent.eventId,
          participantId: currentEventParticipant.participantId,
          selectedLanguage,
          participantsSound,
          gameSound,
        };

        await EventsService.setEventUserData(currentEvent.eventId, {
          selectedLanguage,
          participantsSound,
          gameSound,
        });

        settingsRequestTimeout.current = null;
      }, 1000);
    };
    updateEventUserData();

    return () => {
      if (settingsRequestTimeout.current) {
        clearTimeout(settingsRequestTimeout.current);
        settingsRequestTimeout.current = null;
      }
    };
  }, [
    selectedLanguage,
    participantsSound,
    gameSound,
    currentEventParticipant,
    currentEvent,
  ]);

  const setParticipantsSoundHandle = useCallback(
    (level: any) => {
      setParticipantsSound(level);
    },
    [setParticipantsSound],
  );

  const setGameSoundHandle = useCallback(
    (level: number) => {
      setGameSound(level);
    },
    [setGameSound],
  );

  return {
    participantsSound,
    gameSound,
    setGameSound,
    setParticipantsSound,
    setGameSoundHandle,
    setParticipantsSoundHandle,
  };
};

export const useParticipantsState = ({
  currentParticipantId,
  currentEventId,
  currentParticipant,
  setParticipants,
  setCurrentParticipant,
  currentRoom,
  setCurrentRoom,
  currentRegion,
  setCurrentRegion,
  setIsParticipantRoomChanged,
  rooms,
}: ParticipantsStateHookProps) => {
  const { sendJSONMessageToWebSocket } = useMytaverse();

  const syncEventParticipantsState = useCallback(async () => {
    if (!currentEventId) {
      return;
    }

    const participantsInfo = await EventsService.getEventConnections(
      currentEventId,
    );

    const participantRoles = { admin: [], coordinator: [], moderator: [] };

    setParticipants((participants) =>
      getInitialMapParticipants(
        participants,
        participantsInfo,
        participantRoles,
      ),
    );
    setCurrentParticipant((prev) => {
      if (!prev) {
        return;
      }

      const participantInfo = participantsInfo.find(
        (participantInfo) =>
          participantInfo.participantId === currentParticipantId,
      );

      if (participantInfo && participantInfo.roomId !== prev?.roomId) {
        return {
          ...prev,
          roomId: participantInfo.roomId,
        };
      }

      return prev;
    });
  }, [currentEventId, currentParticipantId]);

  // Set current room
  useAsyncEffect(async () => {
    if (!currentParticipant) {
      return;
    }

    const webSocketMessages = [];

    if (
      currentParticipant.roomId &&
      (!currentRoom ||
        currentRoom.roomId !== currentParticipant.roomId ||
        currentRoom.gameSessionId !== currentParticipant.gameSessionId)
    ) {
      const room = rooms.find(
        ({ roomId }) => roomId === currentParticipant.roomId,
      );

      if (!room) {
        return;
      }

      // Indicate that it's not a newcomer, just room has been changed
      if (currentRoom && currentRoom.roomId) {
        setIsParticipantRoomChanged(true);
      }

      setCurrentRoom({
        ...room,
        gameSessionId: currentParticipant.gameSessionId,
      });

      webSocketMessages.push({
        action: WebsocketAction.JoinedToRoom,
        timestamp: Date.now(),
        participantId: currentParticipant.participantId,
        eventId: currentParticipant.eventId,
        roomId: room.roomId,
        regionName: currentParticipant.region
          ? currentParticipant.region.region
          : null,
        gameSessionId: currentParticipant.gameSessionId,
        isSpeaker: currentParticipant.isSpeaker,
        isDemoUser: currentParticipant.isDemoUser,
      });
    } else if (!currentParticipant.roomId && currentRoom) {
      setCurrentRoom(null);
      setCurrentRegion(null);
    }

    const currentRegionName = currentRegion ? currentRegion.region : null;
    const currentParticipantRegionName = currentParticipant.region
      ? currentParticipant.region.region
      : null;

    if (currentRegionName !== currentParticipantRegionName) {
      setCurrentRegion(currentParticipant.region);

      if (currentParticipant.region) {
        webSocketMessages.push({
          action: WebsocketAction.JoinedToRegion,
          timestamp: Date.now(),
          participantId: currentParticipant.participantId,
          region: currentParticipant.region.region,
        });

        MytaverseLogger.log(
          `${currentParticipant.fullName} joined to ${currentParticipant.region?.region} region`,
        );
      }

      if (currentRegionName && !currentParticipantRegionName) {
        webSocketMessages.push({
          action: WebsocketAction.LeftRegion,
          timestamp: Date.now(),
          participantId: currentParticipant.participantId,
          region: currentRegion?.region,
        });

        MytaverseLogger.log(
          `${currentParticipant.fullName} left ${currentRegionName} region`,
        );
      }
    }

    if (webSocketMessages.length !== 0) {
      await Promise.all(
        webSocketMessages.map((webSocketMessage) =>
          sendJSONMessageToWebSocket(webSocketMessage),
        ),
      );
    }
  }, [currentParticipant, currentRoom, currentRegion]);

  useEffect(() => {
    const interval = setInterval(async () => {
      await syncEventParticipantsState();
    }, 30000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [syncEventParticipantsState]);

  const setParticipantState: SetParticipantStateType = useCallback(
    (participantId, participantState) => {
      if (currentParticipantId === participantId) {
        setCurrentParticipant((prev) => {
          if (!prev) {
            return prev;
          }

          return {
            ...prev,
            ...participantState,
          };
        });
      }

      setParticipants((prev) => {
        const isNewParticipant = !prev.some(
          (participant) => participant.participantId === participantId,
        );

        if (isNewParticipant) {
          return [
            ...prev,
            {
              ...ParticipantService.getEmptyParticipant(participantId),
              ...participantState,
            } as IParticipant,
          ];
        }

        return prev.map((participant) => {
          if (participant.participantId === participantId) {
            return {
              ...participant,
              ...participantState,
            };
          }

          return participant;
        });
      });
    },
    [currentParticipantId],
  );

  return {
    setParticipantState,
  };
};

export const useInitMessage = ({
  token,
  idToken,
  websocketSessionId,
  currentParticipant,
  currentEventParticipant,
  dolbyToken,
  currentEvent,
  teleportingToRoom,
  user,
  setPlayerSession,
}: UseInitMessageProps) => {
  const { showNotification, getStreamingNotification } = useNotification();
  const navigate = useNavigate();

  const webSocketUrl = new URL(
    process.env.REACT_APP_SPATIAL_MANAGER_WEB_SOCKET_URL as string,
  );

  webSocketUrl.searchParams.append('token', token as string);
  webSocketUrl.searchParams.append('clientType', 'UE5');
  webSocketUrl.searchParams.append('session', websocketSessionId);

  const storageParticipantPosition =
    getSessionStorageValue<ParticipantPosition>(
      SessionStorage.ParticipantPosition,
    );
  const defaultParticipantPosition = {
    timestamp: 0,
    x: 0,
    y: 0,
    z: 0,
    r: 0,
  };

  const playerSessionEmptyHandle = useCallback(() => {
    // showNotification(
    //   getStreamingNotification({
    //     message: 'playerSession does not exist',
    //   }),
    // );
    // MytaverseLogger.log('playerSession is empty');
    // navigate(ROUTES.SELECT_EVENT);
  }, []);

  const initMessageHandler =
    useCallback(async (): Promise<IInitMessage | null> => {
      if (
        !token ||
        !idToken ||
        !currentParticipant ||
        !user ||
        !teleportingToRoom ||
        !currentEvent
      ) {
        return null;
      }

      const playerSession = await EventsService.createGameliftPlayerSession(
        currentEvent.eventId,
        teleportingToRoom.roomId,
      );

      setPlayerSession(playerSession);
      // if (!playerSession) {
      //   playerSessionEmptyHandle();
      //   return null;
      // }

      const apiUrl = (
        process.env.REACT_APP_SPATIAL_MANAGER_API_URL as string
      ).includes('localhost')
        ? 'https://api.dev.mytaverse.com'
        : (process.env.REACT_APP_SPATIAL_MANAGER_API_URL as string).replace(
            /\/$/,
            '',
          );

      const participantFullName = user.name;
      const [participantFirstName, participantLastName] =
        participantFullName.split(' ');

      const hasUniform =
        user?.hrData?.ixrCredentialStatus?.toUpperCase() ===
          IXRHrCredStatusTypes.FT &&
        IXR_HR_BRAND_UNIFORMS.includes(user?.hrData?.brand?.toUpperCase());

      const message: IInitMessage = {
        action: InitMessageActions.INIT,
        token: `Bearer ${token}`,
        webSocketUrl,
        apiUrl,
        participantId: currentParticipant.participantId,
        participantFullName,
        participantFirstName,
        participantLastName: participantLastName || '',
        role: currentEventParticipant?.role || '',
        changePositionInterval: 1,
        eventId: currentEvent.eventId,
        avatarId: user.avaturnId || '',
        skinId: null,
        roomId: teleportingToRoom.roomId,
        screenHeight: window.innerHeight >= 1080 ? 1080 : window.innerHeight,
        screenWidth: window.innerWidth >= 1980 ? 1980 : window.innerWidth,
        downloadAvatarUrl: user.customAvatarUrl || '',
        isMobile,
        deviceProfile: getDeviceProfile(),
        dolbyToken: '',
        isSpeaker: currentParticipant.isSpeaker,
        useLastParticipantPosition: !!storageParticipantPosition,
        lastParticipantPosition:
          storageParticipantPosition ?? defaultParticipantPosition,
        gamelift: {
          playerSession,
        },
        mapName: teleportingToRoom.map?.ueIdentifier || '',
        multiPlayer: !!teleportingToRoom.isMultiPlayer,
        hrData: user?.hrData,
        gender: user?.hrData?.gender || 'M',
        hasUniform: !!hasUniform,
      };

      return message;
    }, [
      token,
      websocketSessionId,
      currentParticipant,
      dolbyToken,
      currentEvent,
      teleportingToRoom,
      isMobile,
      playerSessionEmptyHandle,
      user,
    ]);

  return {
    initMessageHandler,
  };
};

export const useAvatarMessage = ({
  currentSkin,
  currentEvent,
  customAvatarUrl,
}: {
  currentSkin: IAvatarSkin | null;
  currentEvent: IEvent | undefined;
  customAvatarUrl: string | null;
}) => {
  const avatarMessage = useMemo(() => {
    if (!currentEvent) {
      return null;
    }

    const currentAvatarType = currentSkin
      ? currentEvent?.avatars.find(
          (avatar) => avatar.avatarId === currentSkin.avatarId,
        )?.type
      : '';

    return {
      avatarId: currentSkin ? currentSkin.avatarId : '',
      type: currentAvatarType,
      skinId: currentSkin ? currentSkin.avatarSkinId : '',
      downloadAvatarUrl: customAvatarUrl || '',
      avatarType: customAvatarUrl ? 'AVATURN' : '',
    };
  }, [currentSkin, currentEvent, customAvatarUrl]);

  return { avatarMessage };
};
