import { Box } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import MicIconOff from '@mui/icons-material/MicOff';

import AssistantImage from '../../../../../public/images/poc/assistant.jpg';

import { useStyles } from './styles';

import { assistantSize, showMuteButtonOnHover } from '../constants';
import { usePocAi } from '../providers/PocAiProvider';

const Assistant = () => {
  const classes = useStyles();

  const { stopPlayback, isMuted, setIsMuted } = usePocAi();

  const handleMicClick = () => {
    try {
      stopPlayback();
    } catch (error) {
      //
    }
    setIsMuted(!isMuted);
  };

  return (
    <Box sx={classes.box}>
      {/*<img src={AssistantImage} />*/}
      <div
        className={'assistant' + (showMuteButtonOnHover ? ' with-hover' : '')}
      >
        <img
          className="assistant-avatar"
          id="ai-assistant"
          src={AssistantImage}
          style={{
            width: assistantSize.width + 'px',
            height: assistantSize.height + 'px',
            /*marginLeft: assistantSize.height / 2 + 'px',
            marginTop: assistantSize.height / 2 + 'px'*/
          }}
        />
        <div
          className={'assistant-mute' + (isMuted ? ' muted' : '')}
          onClick={() => handleMicClick()}
        >
          {isMuted ? <MicIconOff /> : <MicIcon />}
        </div>
        <canvas
          id="ai-assistant-canvas"
          className="ai-assistant-canvas"
        ></canvas>
      </div>
    </Box>
  );
};

export default Assistant;
