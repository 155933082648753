export enum StorageValues {
  HiddenMuteModalEvents = 'hiddenMuteModalEvents',
  DismissMessages = 'dismissMessages',
  Assistant = 'assistant',
}

export enum StorageTypes {
  Local = 'local',
  Session = 'session',
}
