import React from 'react';
import { Modal, Box, Fade } from '@mui/material';

import Assistant from '../Assistant';
import { usePocAi } from '../providers/PocAiProvider';
import {
  DefaultScreen,
  HowToScreen,
  ChatScreen,
  TeleportScreen,
} from '../Screens';
import { POC_SCREENS } from '../constants';

import { useStyles } from './styles';

const PocAiDashboard = () => {
  const classes = useStyles();
  const { activeScreen, open, setOpen } = usePocAi();

  const renderScreen = () => {
    switch (activeScreen) {
      case POC_SCREENS.HOW_TO: {
        return <HowToScreen />;
      }
      case POC_SCREENS.CHAT: {
        return <ChatScreen />;
      }
      case POC_SCREENS.TELEPORT: {
        return <TeleportScreen />;
      }
      default: {
        return <DefaultScreen />;
      }
    }
  };

  return (
    <Modal open={open} sx={classes.modal} BackdropComponent={() => null}>
      <Box sx={classes.box(activeScreen !== POC_SCREENS.DEFAULT)}>
        <Assistant />
        {renderScreen()}
      </Box>
    </Modal>
  );
};

export default PocAiDashboard;
