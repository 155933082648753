import {
  getStorageValue,
  setStorageValue,
} from '../../../helpers/storage';

import { IStreamChannel } from '../../../interfaces/channel';
import { IGetScrollbarStyles } from '../interfaces';
import { StorageValues } from '../../../interfaces/storage';
import { StreamingProviders } from '../providers/MytaverseEventProvider/interfaces';
import { CheckStreamingProviderType } from './interfaces';

export const checkIsStreamChannelUnread = (
  streamChannel: IStreamChannel | null,
): boolean => {
  if (!streamChannel) return false;

  const lastReadDate = streamChannel.lastRead();
  const unreadCount = streamChannel.countUnread(lastReadDate);

  return !!unreadCount;
};

export const getScrollbarStyles: IGetScrollbarStyles = ({
  width,
  borderWidth,
}) => ({
  '*::-webkit-scrollbar': {
    height: '14px',
    width,
    backgroundColor: 'transparent',
  },
  '*::-webkit-scrollbar-corner': {
    backgroundColor: 'transparent',
  },
  '*::-webkit-scrollbar-thumb': {
    backgroundColor: '#FFFFFF4D',
    border: `${borderWidth}px solid transparent`,
    backgroundClip: 'padding-box',
    borderRadius: '18px',
  },
  scrollbarWidth: 'thin',
  scrollbarColor: 'transparent #FFFFFF4D',
});

export const checkMuteModalHidden = (eventId: string): boolean => {
  const hiddenMuteModalEvents =
    getStorageValue<string[]>(StorageValues.HiddenMuteModalEvents) || [];
  const isMuteModalHidden = hiddenMuteModalEvents.includes(eventId);

  return isMuteModalHidden;
};

export const saveMuteModalHidden = (eventId: string) => {
  const hiddenMuteModalEvents =
    getStorageValue<string[]>(StorageValues.HiddenMuteModalEvents) || [];

  setStorageValue(StorageValues.HiddenMuteModalEvents, [
    ...hiddenMuteModalEvents,
    eventId,
  ]);
};

export const checkIsPurewebStreamingProvider: CheckStreamingProviderType = (
  streamingProvider,
) => streamingProvider === StreamingProviders.Pureweb;
