/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prefer-const */
import React, { useCallback, useEffect, useRef } from 'react';
import { showInitMessage } from './helpers';
import {
  ICreateGameSessionResponse,
  IGameCast,
  IGameCastArgs,
  IGamecastServerDisconnectReason,
  IGamecastVideoStats,
} from './interfaces';
import GameCastService from '../../../../../services/GameCastService';
import {
  GAME_CAST_REQUEST_TIMEOUT,
  disallowKeyCode,
  enableDebug,
} from './constants';
import { IInitMessage } from '../Pureweb/interfaces';
import { StreamingProviders } from '../../../providers/MytaverseEventProvider/interfaces';
import { IParticipant } from '../../../../../interfaces/participants';
import { RegisterTouchToMouse } from './touchtomouse';
import { useMytaverse } from '../../../../../providers/MytaverseProvider';
import { useChatState } from '../../../../../hooks/context';
import { IRoom } from '../../../../../interfaces/rooms';

export const useGameInitialization = ({
  gameName,
  description,
  streamId,
  setStreamingProvider,
  setIsGamecastCrash,
  setGamecastStats,
  currentRoom,
}: {
  gameName: string | null;
  description: string;
  browserType: string;
  streamId: string | null;
  currentParticipant: IParticipant | undefined;
  setStreamingProvider: React.Dispatch<
    React.SetStateAction<StreamingProviders | null>
  >;
  setIsGamecastCrash: React.Dispatch<React.SetStateAction<boolean>>;
  setGamecastStats: React.Dispatch<
    React.SetStateAction<IGamecastVideoStats | null>
  >;
  currentRoom: IRoom | null | undefined;
}) => {
  const { sendMessageToUnreal, navigateToEventsPage, user } = useMytaverse();
  const { setOpen: setOpenChat } = useChatState();

  const gameCastRef = useRef<null | IGameCast>(null);

  const initializeStream = async (
    videoElement: HTMLVideoElement,
    participantId: string,
    websocketSessionId: string,
    initMessage: IInitMessage,
    region: string,
  ) => {
    if (!gameName || !streamId) {
      return;
    }
    //@ts-ignore
    const GameCastClass = window.gamecastsdk.GameCast;

    if (enableDebug) {
      //@ts-ignore
      window.gamecastsdk.setLogLevel('debug');
    }

    const gamecast: IGameCast = new GameCastClass({
      videoElement,
      inputConfiguration: {
        autoMouse: true,
        autoKeyboard: true,
        autoGamepad: true,
        hapticFeedback: true,
        setCursor: 'visibility',
        autoPointerLock: true,
        keyboardFilter: (event) => {
          if (enableDebug) {
            console.warn('gamecast keyboard event', event);
          }

          if (user &&
            user.roles?.length &&
            user.roles.indexOf('SUPER_ADMIN') === -1 &&
            disallowKeyCode.indexOf(event.code) > -1) {
            if (enableDebug) {
              console.warn('disallow key input', event.code);
            }
            return false;
          }

          return true;
        },
      },
      clientConnection: {
        //TODO check how we can use it
        connectionState: (state) => {
          console.log('Gamecast connection state: ' + state);
        },
        channelError: (state) => {
          console.log('serverDisconnect state: ' + state);
        },
        serverDisconnect: async (state: IGamecastServerDisconnectReason) => {
          switch (state) {
            case IGamecastServerDisconnectReason.Terminated:
              console.log(state);

              await gamecast.close();
              setIsGamecastCrash(true);
              setOpenChat(false);
              break;
            default:
              console.log(state);
              break;
          }
        },
        applicationMessage: (state) => {
          console.log('applicationMessage state: ' + state);
        },
      },
    } as IGameCastArgs);

    gameCastRef.current = gamecast;

    setInterval(async () => {
      const stats = await gamecast.getRTCStats();
      let data = {
        nameframesPerSecond: null,
        nameframesReceived: null,
        nameframeWidth: null,
        nameframeHeight: null,
        namebytesReceived: null,
        namepacketsReceived: null,
        namepacketsLost: null,
        namebytesSent: null,
        namepacketsSent: null,
      };

      for (const id of stats.keys()) {
        const stat = stats.get(id);
        if (stat.type === 'inbound-rtp') {
          setGamecastStats(stat);
        }
      }
    }, 2000);

    RegisterTouchToMouse(videoElement as any);

    const gamecastSignalData = await gamecast.generateSignalRequest();

    try {
      const signalResponse = await startGameSessionHandler(
        description,
        gameName,
        participantId,
        websocketSessionId,
        gamecastSignalData,
        GAME_CAST_REQUEST_TIMEOUT,
        streamId,
        initMessage,
        region,
        sendMessageToUnreal,
      );

      if (signalResponse) {
        await gamecast.processSignalResponse(signalResponse.SignalResponse);

        gamecast.attachInput();

        return true;
      }
    } catch (e) {
      console.log(e);
    }

    return false;
  };

  const startGameSessionHandler = async (
    description: string,
    gameName: string,
    userId: string,
    websocketSessionId: string,
    signalRequest: string,
    timeout: number,
    streamId: string,
    initMessage: IInitMessage,
    region: string,
    sendMessageToUnreal: (message: any) => void,
  ) => {
    showInitMessage(JSON.stringify(initMessage));

    const response: ICreateGameSessionResponse =
      await createAndWaitForStreamToBeActive(
        description,
        gameName,
        userId as string,
        websocketSessionId as string,
        signalRequest,
        timeout,
        streamId,
        initMessage,
        region,
      );

    if (!response) {
      return navigateToEventsPage(
        'notifications.noAvailableStreamingProviders',
      );
    }

    //It might be here to help UE team with debug
    console.log('Gamecast session config', {
      arn: response.Arn,
      applicationArn: response.ApplicationArn,
      userId: response.UserId,
      streamGroupId: response.StreamGroupId,
    });

    sendMessageToUnreal({
      action: 'CLIENT_CONNECTION_DATA',
      streamingProvider: 'GAMECAST',
      sessionArn: response.Arn,
      region,
    });

    return response;
  };

  const createAndWaitForStreamToBeActive = (
    description: string,
    gameName: string,
    participantId: string,
    websocketSessionId: string,
    signalRequest: string,
    timeout: number,
    streamId: string,
    initMessage: IInitMessage,
    region: string,
  ) => {
    return new Promise<ICreateGameSessionResponse>(async (resolve, reject) => {
      if (!initMessage) {
        return;
      }

      const maxRetriesToStartGamecastPlayerSession = 10;
      let totalRetriesToStartGamecastPlayerSession = 0;

      console.log(`Starting gamecast player session`);

      const startGamecastPlayerSession = async (resolve: any) => {
        try {
          totalRetriesToStartGamecastPlayerSession += 1;

          const gamecastPlayerSession = await GameCastService.startGameSession({
            description,
            gameName,
            participantId,
            signalRequest,
            timeout,
            streamId,
            initMessage,
            region,
            websocketSessionId,
          });

          if (gamecastPlayerSession) {
            console.log(`Gamecast player session started`);

            return resolve({
              SignalResponse: gamecastPlayerSession.SignalResponse,
              Status: gamecastPlayerSession.Status,
              WebSdkProtocolUrl: gamecastPlayerSession.WebSdkProtocolUrl,
              Arn: gamecastPlayerSession.Arn,
              ApplicationArn: gamecastPlayerSession.ApplicationArn,
              StreamGroupId: gamecastPlayerSession.StreamGroupId,
              UserId: gamecastPlayerSession.UserId,
            });
          }
        } catch (e) {
          console.log(`Couldn't start gamecast session`);
        }

        if (
          totalRetriesToStartGamecastPlayerSession >
          maxRetriesToStartGamecastPlayerSession
        ) {
          return resolve(null);
        }

        const delay = totalRetriesToStartGamecastPlayerSession * 1000;
        console.log(
          `Retrying to start gamecast player session with ${delay}ms delay`,
        );

        setTimeout(() => startGamecastPlayerSession(resolve), delay);
      };

      await startGamecastPlayerSession(resolve);
    });
  };

  const handleReconnectGamecast = useCallback(
    async ({
      videoElement,
      participantId,
      websocketSessionId,
      initMessage,
      region,
    }: {
      videoElement: HTMLVideoElement;
      participantId: string;
      websocketSessionId: string;
      initMessage: IInitMessage;
      region: string;
    }) => {
      if (!gameName || !participantId || !streamId || !initMessage) {
        console.log('here');
        return;
      }
      //@ts-ignore
      const GameCastClass = window.gamecastsdk.GameCast;

      const gamecast: IGameCast = new GameCastClass({
        videoElement,
        inputConfiguration: {
          autoMouse: true,
          autoKeyboard: true,
          autoGamepad: true,
          hapticFeedback: true,
          setCursor: 'visibility',
          autoPointerLock: true,
        },
        clientConnection: {
          //TODO check how we can use it
          connectionState: (state) => {
            console.log('Gamecast connection state: ' + state);
          },
          channelError: (state) => {
            console.log('serverDisconnect state: ' + state);
          },
          serverDisconnect: async (state: IGamecastServerDisconnectReason) => {
            switch (state) {
              case IGamecastServerDisconnectReason.Terminated:
                await gamecast.close();
                setIsGamecastCrash(true);
                break;
              default:
                break;
            }
          },
          applicationMessage: (state) => {
            console.log('applicationMessage state: ' + state);
          },
        },
      } as IGameCastArgs);

      console.log('gamecast re-connect');
      gameCastRef.current = gamecast;

      RegisterTouchToMouse(videoElement as any);

      const gamecastSignalData = await gamecast.generateSignalRequest();

      const signalResponse = await startGameSessionHandler(
        description,
        gameName,
        participantId,
        websocketSessionId,
        gamecastSignalData,
        GAME_CAST_REQUEST_TIMEOUT,
        streamId,
        initMessage,
        region,
        sendMessageToUnreal,
      );

      if (signalResponse) {
        await gamecast.processSignalResponse(signalResponse.SignalResponse);

        gamecast.attachInput();
      }
    },
    [gameName, description, streamId],
  );

  useEffect(() => {
    const handleBlur = () => {
      if (!currentRoom) {
        return;
      }

      try {
        gameCastRef.current?.detachInput();
      } catch (e) {
        console.error(e);
      }
    };

    const handleFocus = () => {
      if (!currentRoom) {
        return;
      }

      try {
        gameCastRef.current?.attachInput();
      } catch (e) {
        console.error(e);
      }
    };

    if (gameCastRef.current) {
      window.addEventListener('blur', handleBlur);
      window.addEventListener('focus', handleFocus);
    }

    return () => {
      if (gameCastRef.current) {
        window.removeEventListener('blur', handleBlur);
        window.removeEventListener('focus', handleFocus);
      }
    };
  }, [gameCastRef.current, currentRoom]);

  return {
    initializeStream,
    //streamSession,
    handleReconnectGamecast,
    gameCastRef,
  };
};
